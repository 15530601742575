import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useRef, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import { fetchGenresList } from "../../../helpers/fetcher";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../helpers/axiosInstance";
import { API } from "../../../router/apiEndpoints";
import MediaPlaceHolder from "../../../components/global/mediaPlaceholder";
import style from "./../music_videos/musicVideos.module.scss";

const Favorites = () => {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([{ id: 0, label: "All" }]);

  useEffect(() => {
    const loadGenres = async () => {
      try {
        const data = await fetchGenresList();
  
        setTypes((prev) => {
          // Merge arrays and remove duplicates
          const merged = [...prev, ...data];
          const unique = Array.from(new Set(merged.map((genre) => JSON.stringify(genre))))
            .map((str) => JSON.parse(str)); // Use an arrow function to properly handle JSON.parse
  
          return unique;
        });
      } catch (error) {
        console.error("Error fetching genres:", error);
      }
    };
  
    loadGenres();
  }, []);

  const [type, setType] = useState(types[0]);
  const handleType = (d: any) => {
    // console.log(d);
    setType(d);
    setOpen(false);
    const fetchInitialData = async () => {
      await fetchMediaDataPaginated(6, "latest", d.label, 1, true);
    };

    fetchInitialData();
  };
  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches
  const [medias, setMedias] = useState<any[]>([]);

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // console.log(`Type: `, type);
    const fetchInitialData = async () => {
      await fetchMediaDataPaginated(6, "latest", type.label, 1, true);
    };

    fetchInitialData();
  }, []);

  const fetchMediaDataPaginated = async (
    limit: number,
    mode: string,
    genre: string,
    page: number,
    isInitialLoad = true
  ) => {
    if (isLoading || (!isInitialLoad && !hasMore)) return;

    setIsLoading(true);

    axiosInstance.get(`${API.HOST}${API.VIDEOS.FAVORITE}`, {
      params: {
        page: page
      },
      headers: {
        Application: "application/json",
        "Content-Type": "application/json",
        "X-Page": `${page}`, // Added the X-Page header for pagination
        "X-Limit": `${limit}`, // Fixed limit
        "X-Mode": mode,
        "X-Genre": genre,
        "X-With-Profile-Attached": 1
      }
    }).then((response) => {
      if (response.data.data.length > 0) {
        setMedias(prev => {
          const merged = [...prev, ...response.data.data];
          const unique = merged.filter(
            (item, index, self) => self.findIndex(obj => obj.id === item.id) === index
          );
          return unique;
        });
        setHasMore(response.data.pagination.next_page_url !== null);
      } else {
        setHasMore(false);
      }
    }).catch((error) => {
      console.error("Error fetching media data:", error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 && // Near bottom
        hasMore &&
        !isLoading
      ) {
        fetchMediaDataPaginated(6, "latest", type.label, currentPage + 1); // Increment the page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, hasMore, isLoading]); // Dependencies ensure correct behavior
  const navigate = useNavigate();

  const navigateToHome = () => {
    return () => {
      navigate("/dashboard/home");
    };
  };

  return (
    <div className="">
      {openItem && <MediaDetail setOpen={setOpenItem} data={data} />}
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-medium text-2xl">Favorites</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className={`${style.selected_genre}`}
            onClick={() => setOpen(!open)}
          >
            <div className="">
              <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${open ? "rotate-180" : "rotate-0"
                }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className={`${style.genres}`}>
              {types.map((d: any, index: number) => (
                <li
                  key={index}
                  onClick={() => handleType(d)}
                >
                  {d.label}
                  {d.id === type.id && (
                    <span className={`${style.selected_li_flag}`}></span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="mt-4">
        {!isLoading && medias.length === 0 ? (
          <div className="text-center text-white mt-8">
            <p className="bg-[#003465] max-w-xs py-2 px-4 rounded-xl mx-auto flex items-center justify-center gap-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9877 6.10805C16.0676 5.70836 15.7479 5.22873 15.3482 5.22873L10.7917 4.58922L8.71328 0.43243C8.63334 0.272553 8.5534 0.192615 8.39353 0.112676C7.99383 -0.127139 7.5142 0.032738 7.27439 0.43243L5.27593 4.58922L0.719445 5.22873C0.47963 5.22873 0.319753 5.30867 0.239815 5.46855C-0.0799383 5.7883 -0.0799383 6.26793 0.239815 6.58768L3.51729 9.78522L2.7179 14.3417C2.7179 14.5016 2.7179 14.6615 2.79784 14.8213C3.03766 15.221 3.51729 15.3809 3.91698 15.1411L7.99383 12.9827L12.0707 15.1411C12.1506 15.221 12.3105 15.221 12.4704 15.221H12.6303C13.0299 15.1411 13.3497 14.7414 13.2698 14.2618L12.4704 9.70528L15.7479 6.50774C15.9077 6.4278 15.9877 6.26793 15.9877 6.10805Z"
                  fill="#FFE600"
                />
              </svg>
              <span>No Favorites,</span>
              <span
                className="text-white cursor-pointer"
                onClick={navigateToHome}
              >
                Click here to start
              </span>
            </p>
          </div>
        ) : (
          <div className="mt-8">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4">
              {medias.map((d: any, index: number) => (
                <div key={index}>
                  <MediaCard
                    {...d}
                    idx={index}
                    onClick={() => handleClick(d)}
                  />
                </div>
              ))}
              {isLoading && <MediaPlaceHolder placeholdersCount={3} placeholderType="media" />}
              {!hasMore && <p>No more media available.</p>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Favorites;
