import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import { API } from "../../router/apiEndpoints";
import { ResetPasswordFormInterface } from "../../interfaces/FormInterfaces";
import { HandleTextInputChange } from "../../handlers/forms/ChangeFormHandlers";
import FormNotification from "../../components/notifications/FormNotification";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);
  const [form, setForm] = useState<ResetPasswordFormInterface>({
    password: {
      value: "",
      error: "",
      required: true,
      label: "New Password*"
    },
    password_confirmation: {
      value: "",
      error: "",
      required: true,
      label: "Confirm Password*"
    },
  });

  useEffect(() => {
    console.log(`Token: ${token}, Email: ${email}`);
  }, [token, email]);

  const handleSubmit = async () => {
    console.log(form); // Log the form to check its contents
    setIsLoading(true); // Set loading state to true when the request starts

    try {
      const response = await axiosInstance.post(`${API.HOST}${API.USER.RESET_PASSWORD}`, {
        email: email,
        password: form.password.value,
        password_confirmation: form.password_confirmation.value,
        token: token
      });

      console.log(response);

      // Assuming the API sends back a success message
      if (response.status === 200) {
        setIsRequestSent(true);
      }
    } catch (error) {
      // Handle error (e.g., show an alert or set an error state)
      console.error('Error sending password reset request:', error);
      alert('There was an error sending the reset link. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false after the request ends
    }
  };


  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <form className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src="/img/wizmeek_logo.png"
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Reset Password
            </p>
            {isRequestSent ? (
              <>
                <FormNotification
                  message="Your password has been successfully reset. You can now log in with your new password."
                  mode="success" />
                <a
                  onClick={navigateToLogin}
                  className="text-center mt-[20px] cursor-pointer w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97] disabled:opacity-70 disabled:cursor-not-allowed"
                >Login</a>
              </>
            ) : (
              <div className="flex flex-col gap-2 sm:gap-4 mt-4 w-full">
                <div className="flex flex-col gap-2">
                  <p className="text-base text-white">{form.password.label}</p>
                  <input
                    type="password"
                    value={form.password.value}
                    onChange={(event) => HandleTextInputChange<ResetPasswordFormInterface>(event, setForm)}
                    name="password"
                    className={`rounded-[7px] text-black dark:text-black text-base p-2 ${form.password.error ? "border-2 border-red-500" : ""
                      }`}
                    placeholder=""
                  />
                  {form.password.error && (
                    <p className="text-red-500 text-sm">{form.password.error}</p>
                  )}
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-base text-white">{form.password_confirmation.label}</p>
                  <input
                    type="password"
                    value={form.password_confirmation.value}
                    onChange={(event) => HandleTextInputChange<ResetPasswordFormInterface>(event, setForm)}
                    name="password_confirmation"
                    className={`rounded-[7px] text-black dark:text-black text-base p-2 ${form.password_confirmation.error ? "border-2 border-red-500" : ""
                      }`}
                    placeholder=""
                  />
                  {form.password_confirmation.error && (
                    <p className="text-red-500 text-sm">{form.password_confirmation.error}</p>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97] disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  {isLoading ? "Sending request..." : "Reset my Password"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
