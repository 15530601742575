import style from "./FormNotification.module.scss";

interface AlertMessageProps {
    message: string;
    mode: "success" | "warning" | "error";
}

const FormNotification: React.FC<AlertMessageProps> = ({ message, mode }) => {
    const icons: Record<AlertMessageProps["mode"], JSX.Element> = {
        success: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
            </svg>
        ),
        warning: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM256 120c13.3 0 24 10.7 24 24v160c0 13.3-10.7 24-24 24s-24-10.7-24-24V144c0-13.3 10.7-24 24-24zm0 264c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24z" />
            </svg>
        ),
        error: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM336.9 175.1c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L256 188.1l-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L222.1 256l-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L289.9 256l47-47z" />
            </svg>
        ),
    };

    return (
        <div className={`${style.alert} ${style[mode]}`}>
            {icons[mode]}
            <p>{message}</p>
        </div>
    );
};

export default FormNotification;
