import style from './mediaPlaceholder.module.scss';

const MediaPlaceHolder = ({ placeholdersCount, placeholderType }: { placeholdersCount: number; placeholderType: string }) => {
    // console.log(placeholderType);
    return (
        <>
            {Array.from({ length: placeholdersCount }).map((_, i) => (
                <div 
                    key={i} 
                    className={`rounded-3xl ${style.mediaCardPlaceholder} ${style[`ar_${placeholderType}`]}`}
                />
            ))}
        </>
    );
}

export default MediaPlaceHolder;