interface TextInputInterface {
  value: string,
  error: string,
  required: boolean,
  label: string
}

interface FileInputInterface {
  files: File[],
  error: string,
  required: boolean,
  label: string
  mimes: string[]
}

export interface ForgottenPasswordFormInterface {
  email: TextInputInterface
}

export interface ResetPasswordFormInterface {
    password: TextInputInterface,
    password_confirmation: TextInputInterface
}

export interface FeedbackFormInterface {
  subject: TextInputInterface,
  message: TextInputInterface,
  files: FileInputInterface
}

export const initialFeedbackFormState: FeedbackFormInterface = {
  subject: {
    value: '',
    error: '',
    required: true,
    label: 'Subject*'
  },
  message: {
    value: '',
    error: '',
    required: true,
    label: 'Message*'
  },
  files: {
    files: [], // Empty array for files initially
    error: '',
    required: false,
    label: 'Attach files',
    mimes: [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.svg',
      '.pdf',
      '.doc',
      '.docx',
      '.mp4',
      '.mov',
      '.avi'
    ]
  }
};