import React, { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import { API } from "../../router/apiEndpoints";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";
import { ForgottenPasswordFormInterface } from "../../interfaces/FormInterfaces";
import { HandleTextInputChange } from "../../handlers/forms/ChangeFormHandlers";
import { AxiosError } from "axios";
import FormNotification from "../../components/notifications/FormNotification";

const ForgottenPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);
  const [form, setForm] = useState<ForgottenPasswordFormInterface>({
    email: {
      value: "",
      error: "",
      required: true,
      label: "Email*"
    }
  });

  const handleSubmit = async () => {
    console.log(form);
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`${API.HOST}${API.USER.FORGOT_PASSWORD_REQUEST}`, {
        email: form.email.value
      });

      console.log(response);

      if (response.status === 200) {
        setIsRequestSent(true);
      }
    } catch (error) {
      console.error('Error sending password reset request:', error);
      setForm((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          error: (error as any)?.response?.data?.error || 'An unexpected error occurred'
        }
      }));
    } finally {
      setIsLoading(false);
    }
  };


  const navigateToSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <form className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src="/img/wizmeek_logo.png"
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Forgot Password
            </p>
            {isRequestSent ? (
              <FormNotification
                message="Your password reset request has been successfully sent. Please check your email for further instructions on how to reset your password. If you don't see the email, check your spam folder or try requesting a new one."
                mode="success" />
            ) : (
              <div className="flex flex-col gap-2 sm:gap-4 mt-4 w-full">
                <div className="flex flex-col gap-2">
                  <p className="text-base text-white">{form.email.label}</p>
                  <input
                    type="email"
                    value={form.email.value}
                    onChange={(event) => HandleTextInputChange<ForgottenPasswordFormInterface>(event, setForm)}
                    name="email"
                    className={`rounded-[7px] text-black dark:text-black text-base p-2 ${form.email.error ? "border-2 border-red-500" : ""
                      }`}
                    placeholder="username@gmail.com"
                  />
                  {form.email.error && (
                    <p className="text-red-500 text-sm">{form.email.error}</p>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97] disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  {isLoading ? "Sending request..." : "Reset my Password"}
                </button>

                <div className="flex flex-col gap-2">
                  <p className="text-[12px] text-white">
                    Don't Have An Account?{" "}
                    <a
                      className="font-bold cursor-pointer select-none"
                      rel="noopener noreferrer"
                      onClick={navigateToSignUp}
                    >
                      Click Here
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgottenPassword;
