import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import footerData from "../../constant";
import CallToSignup from "../../components/popups/callToSignup";
const DashboardLayout = () => {
  const popupRef = useRef<{ showPopUp: () => void; setMessage: (msg: string) => void } | null>(null);
  return (
    <>
      {/* Popup - Call To Signup */}
      <CallToSignup ref={popupRef} />
      {/* // Popup - Call To Signup */}
      <Sidebar
        setSignUpPopUpOpen={() => popupRef.current?.showPopUp()}
        setSignUpPopUpMessage={(newMessage: string) => popupRef.current?.setMessage(newMessage)} />
      <Header />
      <main className="lg:ml-80 pt-20 px-6 min-h-screen flex flex-col">
        <div className="flex-grow pb-10">
          <Outlet />
        </div>
        <Footer footerData={footerData} />
      </main>
    </>
  );
};

export default DashboardLayout;
