import MediaCard from "./mediaCard";

const MediaDetail = (props: any) => {
  const { setOpen, data, setSignUpPopUpOpen, setSignUpPopUpMessage } = props;
  // console.log("Medai Detail Props: ", props);
  return (
    <div className="space-y-4">
      <div className="flex justify-end items-center">
        <button onClick={() => setOpen(false)} className="">
          <svg
            width="24"
            height="24"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.0194 21.6011L28.4694 33.0511C29.0769 33.6587 29.901 34 30.7602 34C31.6195 34 32.4435 33.6587 33.0511 33.0511C33.6587 32.4435 34 31.6195 34 30.7603C34 29.901 33.6587 29.077 33.0511 28.4694L21.5968 17.0194L33.0489 5.5694C33.3496 5.26856 33.5881 4.91144 33.7508 4.51842C33.9135 4.12541 33.9972 3.7042 33.9971 3.27885C33.997 2.8535 33.9131 2.43233 33.7502 2.03939C33.5874 1.64646 33.3487 1.28945 33.0479 0.988751C32.747 0.688052 32.3899 0.449553 31.9969 0.286871C31.6039 0.124188 31.1827 0.0405078 30.7573 0.040608C30.332 0.0407083 29.9108 0.124587 29.5178 0.287455C29.1249 0.450322 28.7679 0.688991 28.4672 0.989831L17.0194 12.4398L5.56936 0.989831C5.27074 0.680362 4.91348 0.433462 4.51843 0.26354C4.12337 0.0936174 3.69843 0.00407408 3.26839 0.000135819C2.83836 -0.00380244 2.41185 0.0779425 2.01374 0.240601C1.61564 0.40326 1.25392 0.643575 0.949683 0.947523C0.645447 1.25147 0.404792 1.61297 0.241758 2.01092C0.0787236 2.40887 -0.00342347 2.8353 0.000109289 3.26534C0.00364205 3.69537 0.0927836 4.1204 0.262333 4.51562C0.431883 4.91084 0.678446 5.26833 0.987633 5.56724L12.442 17.0194L0.989794 28.4716C0.680606 28.7705 0.434043 29.128 0.264493 29.5232C0.0949436 29.9184 0.00580105 30.3434 0.00226829 30.7735C-0.00126447 31.2035 0.0808826 31.6299 0.243917 32.0279C0.406951 32.4258 0.647606 32.7873 0.951842 33.0913C1.25608 33.3952 1.6178 33.6355 2.0159 33.7982C2.41401 33.9608 2.84052 34.0426 3.27055 34.0387C3.70058 34.0347 4.12553 33.9452 4.52058 33.7753C4.91564 33.6053 5.2729 33.3584 5.57152 33.049L17.0194 21.6011Z"
              fill="#FBFBFB"
            />
          </svg>
        </button>
      </div>
      <div className="flex max-xl:flex-col justify-between max-lg:items-center gap-8">
        <iframe
          className="flex-grow flex-shrink-0 max-lg:w-full !aspect-video"
          src={`https://www.youtube.com/embed/${data.youtube_id}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen={true}
          data-vidid=""
        ></iframe>
        <MediaCard
          cardType="detail"
          className="xl:w-[31.5%] w-full"
          {...data}
          setSignUpPopUpOpen={() => setSignUpPopUpOpen()}
          setSignUpPopUpMessage={(msg: string) => setSignUpPopUpMessage(msg)}
        />
      </div>
    </div>
  );
};
export default MediaDetail;
