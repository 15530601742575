export const HandleTextInputChange = <DataType extends Record<string, any>>(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setForm: React.Dispatch<React.SetStateAction<DataType>>
) => {
    const { name, value } = event.target;

    setForm((prevState) => ({
        ...prevState,
        [name]: {
            ...prevState[name as keyof DataType],
            value: value,
            error: ""
        }
    }));
}

export const HandleFileInputChange = <DataType extends Record<string, any>>(
    event: React.ChangeEvent<HTMLInputElement>,
    setForm: React.Dispatch<React.SetStateAction<DataType>>
) => {
    const { name, files } = event.target;

    if (files && files.length > 0) {
        setForm((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name as keyof DataType],
                files: [
                    ...prevState[name as keyof DataType].files, 
                    ...Array.from(files)
                ],
                error: ""
            }
        }));
    }
};

export const SetInputError = <DataType extends Record<string, any>>(
    name: string,
    value: string,
    setform: React.Dispatch<React.SetStateAction<DataType>>
) => {
    setform((prevState) => ({
        ...prevState,
        [name]: {
            ...prevState[name as keyof DataType],
            error: value
        }
    }));
}

export const HandleFileRemove = <DataType extends Record<string, any>>(
    index: number,
    name: string,
    setForm: React.Dispatch<React.SetStateAction<DataType>>
) => {
    setForm((prevState) => ({
        ...prevState,
        [name]: {
            ...prevState[name as keyof DataType],
            files: prevState[name as keyof DataType].files.filter((_: File, i: number) => i !== index),
        }
    }));
};