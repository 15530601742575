import { useEffect, useRef, useState } from "react";
import { FeedbackFormInterface, initialFeedbackFormState } from "../../interfaces/FormInterfaces";
import { HandleFileInputChange, HandleFileRemove, HandleTextInputChange, SetInputError } from "../../handlers/forms/ChangeFormHandlers";
import style from "./FeedbackWindow.module.scss";
import axiosInstance from "../../helpers/axiosInstance";
import { API } from "../../router/apiEndpoints";

interface FeedbackWindowProps {
    isShown: boolean;
    setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeedbackWindow: React.FC<FeedbackWindowProps> = ({ isShown, setIsFeedbackOpen }) => {
    const [form, setForm] = useState<FeedbackFormInterface>(initialFeedbackFormState);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isSent, setIsSent] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        console.log(form.files);
    }, [form]);

    const submitFeedback = async () => {
        setIsSending(true);

        const formData = new FormData();
        formData.append('subject', form.subject.value);
        formData.append('message', form.message.value);

        form.files.files.forEach((file: File) => {
            formData.append('files[]', file);
        });

        await axiosInstance.post(`${API.HOST}${API.USER.AUTH.FEEDBACK.STORE}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            console.log(response);
            setIsSent(true);
            setTimeout(() => {
                setIsSent(false);
                setIsFeedbackOpen(false);
                formRef?.current?.reset();
            }, 3000);
        }).catch((error) => {
            console.log(error);
            if (error?.response?.status === 422) {
                const errors = error.response.data.errors;

                Object.keys(errors).forEach((field) => {
                    SetInputError(field, errors[field][0], setForm);
                });
            }
        }).finally(() => {
            setIsSending(false);
        })
    }

    return (
        <div className={`${style.feedbackWindow} ${isShown && style.show}`}>
            <form className={style.feedbackForm} ref={formRef}>

                <button type="button" className={style.feedbackForm__close} onClick={() => setIsFeedbackOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                </button>

                {/* Subject */}
                <div className="flex flex-col gap-2">
                    <label className="text-base text-white" htmlFor="subject">{form.subject.label}</label>
                    <input
                        type="text"
                        value={form.subject.value}
                        onChange={(event) => HandleTextInputChange<FeedbackFormInterface>(event, setForm)}
                        name="subject"
                        id="subject"
                        className={`rounded-[7px] text-black dark:text-black text-base p-2 ${form.subject.error ? "border-2 border-red-500" : ""}`}
                        placeholder=""
                    />
                    {form.subject.error && (
                        <p className="text-red-500 text-sm">{form.subject.error}</p>
                    )}
                </div>
                {/* // Subject */}

                {/* Message */}
                <div className="flex flex-col gap-2">
                    <label className="text-base text-white" htmlFor="message">{form.message.label}</label>
                    <textarea
                        name="message"
                        id="message"
                        onChange={(event) => HandleTextInputChange<FeedbackFormInterface>(event, setForm)}
                        className={`rounded-[7px] text-black dark:text-black text-base p-2 ${form.message.error ? "border-2 border-red-500" : ""}`}
                        placeholder="">
                        {form.message.value}
                    </textarea>
                    {form.message.error && (
                        <p className="text-red-500 text-sm">{form.message.error}</p>
                    )}
                </div>
                {/* // Message */}

                {/* Files */}
                <div className="flex flex-col gap-2">
                    <div className={style.feedbackForm__filesList}>
                        {form.files.files.map((file, index) => (
                            <div key={index} className={style.feedbackForm__filesList__file}>
                                <span className={style.feedbackForm__filesList__fileName}>{file.name}</span>
                                <span className={style.feedbackForm__filesList__fileSize}>{parseInt(file.size / 1024 + "", 10)}kB</span>
                                <button type="button" onClick={() => HandleFileRemove(index, 'files', setForm)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>
                    <label className={style.feedbackForm__uploadLabel} htmlFor="files">
                        <div className="">
                            <span className="">{form.files.label}</span>
                            <span className={style.feedbackForm__uploadLabel__mimes}>Allowed extensions: {form.files.mimes.join(', ')}</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                        </svg>
                    </label>
                    <input
                        name="files"
                        id="files"
                        type="file"
                        accept={form.files.mimes.join(', ')}
                        onChange={(event) => HandleFileInputChange<FeedbackFormInterface>(event, setForm)}
                        className={style.feedbackForm__uploadInput} />
                    {form.files.error && (
                        <p className="text-red-500 text-sm">{form.files.error}</p>
                    )}
                </div>
                {/* // Files */}

                {/* Submit */}
                <div className="flex flex-col gap-2">
                    <button
                        type="button"
                        className={style.feedbackForm__submit}
                        onClick={submitFeedback}
                        disabled={isSending}>
                        {isSending && "Sending..."}
                        {isSent && "Thank you!"}
                        {!isSending && !isSent ? "Submit Feedback" : ""}
                    </button>
                </div>
                {/* // Submit */}

            </form>
        </div>
    );
}

export default FeedbackWindow;