import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_APP_KEY, // Reverb uses this but ignores the cluster
    wsHost: "localhost", // Change this to your Laravel server's IP if it's remote
    wsPort: 8876, // Reverb is running on 8080 inside Laravel
    wssPort: 8876, // If using wss (SSL), but should be 8080 for now
    forceTLS: false, // Keep false unless using HTTPS
    disableStats: true,
    encrypted: false, // Ensure it's not encrypted for HTTP connections
    cluster: "", // Pusher requires this, but Reverb doesn't use it
    enabledTransports: ["ws", "wss"], // Only WebSockets, disable polling
});

export default echo;