import { faHome, faVideo, faHeadphones, faMicrophoneLines, faUsers } from '@fortawesome/free-solid-svg-icons';

const SidebarNavLinks: any[] = [
  {
    icon: faHome,
    title: "Home",
    path: "/",
  },
  {
    icon: faVideo,
    title: "Music Videos",
    path: "/music-videos",
  },
  {
    icon: faHeadphones,
    title: "Official Audio",
    path: "/official-audio",
  },
  {
    icon: faMicrophoneLines,
    title: "Artists",
    path: "/artists",
  },
  {
    icon: faUsers,
    title: "About US",
    path: "/about-us",
  },
];
export default SidebarNavLinks;
