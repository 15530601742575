import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useRef, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import {
  fetchAudioTypeMediaData,
  fetchGenresList,
} from "../../../helpers/fetcher";
import MediaPlaceHolder from "../../../components/global/mediaPlaceholder";
import CallToSignup from "../../../components/popups/callToSignup";
import style from "./../music_videos/musicVideos.module.scss"

const OfficialAudios = () => {
  const popupRef = useRef<{ showPopUp: () => void; setMessage: (msg: string) => void } | null>(null);
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([{ id: 0, label: "All" }]);

  useEffect(() => {
    const loadGenres = async () => {
      try {
        const data = await fetchGenresList();
  
        setTypes((prev) => {
          // Merge arrays and remove duplicates
          const merged = [...prev, ...data];
          const unique = Array.from(new Set(merged.map((genre) => JSON.stringify(genre))))
            .map((str) => JSON.parse(str)); // Use an arrow function to properly handle JSON.parse
  
          return unique;
        });
      } catch (error) {
        console.error("Error fetching genres:", error);
      }
    };
  
    loadGenres();
  }, []);

  const [type, setType] = useState(types[0]);
  const handleType = (d: any) => {
    // console.log(d);
    setType(d);
    setOpen(false);
    const fetchInitialData = async () => {
      await fetchMediaDataPaginated(6, "latest", d.label, 1, true);
    };

    fetchInitialData();
  };
  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches
  const [medias, setMedias] = useState<any[]>([]);

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchMediaDataPaginated(6, "latest", "", 1, true);
    };

    fetchInitialData();
  }, []);

  const fetchMediaDataPaginated = async (
    limit: number,
    mode: string,
    genre: string,
    page: number,
    isInitialLoad = false
  ) => {
    if (isLoading || (!isInitialLoad && !hasMore)) return;

    setIsLoading(true);
    try {
      const response = await fetchAudioTypeMediaData(limit, mode, genre, page); // Ensure the correct page is always passed
      if (response.data.length > 0) {
        // For initial load, replace media data instead of appending
        if (isInitialLoad) {
          // console.log(response.data);
          setMedias(response.data);
        } else {
          setMedias((prev) => [...prev, ...response.data]);
        }

        setHasMore(response.pagination.next_page_url !== null); // Update hasMore based on the API response
      } else {
        setHasMore(false); // No more data
        setMedias([]);
      }

      // Update currentPage only if it's not the initial load
      if (!isInitialLoad) setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching media data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100 && // Near bottom
        hasMore &&
        !isLoading
      ) {
        fetchMediaDataPaginated(6, "latest", type.label, currentPage + 1); // Increment the page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, hasMore, isLoading]); // Dependencies ensure correct behavior

  return (
    <div className="">
    {/* Popup - Call To Signup */}
    <CallToSignup ref={popupRef} />
    {/* // Popup - Call To Signup */}

      {openItem && <MediaDetail setOpen={setOpenItem} data={data} />}
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-medium text-2xl">Official Audio</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className={`${style.selected_genre}`}
            onClick={() => setOpen(!open)}
          >
            <div className="">
              <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${
                open ? "rotate-180" : "rotate-0"
              }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className={`${style.genres}`}>
              {types.map((d: any, index: number) => (
                <li
                  key={index}
                  onClick={() => handleType(d)}
                >
                  {/* {console.log(d.id, type.id, d.id == type.id)} */}
                  {d.label}
                  {d.id === type.id ? (
                    <span className={`${style.selected_li_flag}`}></span>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4">
            {medias.map((d: any, index: number) => (
              <div key={index}>
                <MediaCard 
                  {...d} 
                  idx={index} 
                  onClick={() => handleClick(d)}
                  setSignUpPopUpOpen={ () => popupRef.current?.showPopUp() }
                  setSignUpPopUpMessage={ (newMessage: string) => popupRef.current?.setMessage(newMessage) } />
              </div>
            ))}
            { isLoading && <MediaPlaceHolder placeholdersCount={3} placeholderType="media" /> }
            {!hasMore && <p>No more media available.</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfficialAudios;
