import React, { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import { API } from "../../router/apiEndpoints";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";

interface FormErrors {
  email?: string;
  password?: string;
  general?: string;
}

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const [state, setState] = useState({
    email: "",
    password: "",
    remember: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (errors[e.target.name as keyof FormErrors]) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrors({});
    const formData = {
      email: state.email,
      password: state.password,
      remember: state.remember ? 1 : 0
    }

    try {
      // Attempt login
      const loginResponse = await axiosInstance.post(
        `${API.HOST}${API.AUTH.LOGIN}`,
        formData
      );

      console.log("Login Response:", loginResponse.data);

      if (loginResponse.data.error) {
        setErrors({ general: loginResponse.data.error });
        return;
      }

      // Set cookies and local storage
      Cookies.set("isLoggedIn", "true", { expires: 7 });
      if (state.remember) {
        localStorage.setItem("email", state.email);
      }

      // Get user profile data
      const profileResponse = await axiosInstance.get(
        `${API.HOST}${API.USER.GET_PROFILE}`
      );
      console.log("Profile Response:", profileResponse.data);

      // Store user data in cookie
      Cookies.set("user", JSON.stringify(profileResponse.data), { expires: 7 });

      // Update context
      await login();

      // Navigate to dashboard
      console.log("Navigating to dashboard...");
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 100);
    } catch (error: any) {
      console.error("Login Error:", error);
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setErrors({ general: error.response.data.message });
      } else {
        setErrors({
          general: "An error occurred during login. Please try again.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToSignUp = () => {
    navigate("/signup");
  };

  const redirect = (path: string) => {
    navigate(`/${path}`);
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <form className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src="/img/wizmeek_logo.png"
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Login
            </p>
            <div className="flex flex-col gap-2 sm:gap-4 mt-4 w-full">
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Email</p>
                <input
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                  name="email"
                  className={`rounded-[7px] text-black dark:text-black text-base p-2 ${
                    errors.email ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="username@gmail.com"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Password</p>
                <input
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                  name="password"
                  className={`rounded-[7px] text-black dark:text-black text-base p-2 ${
                    errors.password ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="Password"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  checked={state.remember}
                  className="w-3 h-3"
                  onChange={() =>
                    setState({ ...state, remember: !state.remember })
                  }
                />
                <label
                  htmlFor="remember"
                  className="text-[12px] text-white select-none cursor-pointer"
                >
                  Remember me
                </label>
              </div>

              {errors.general && (
                <p className="text-red-500 text-sm text-center">
                  {errors.general}
                </p>
              )}

              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97] disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {isLoading ? "Signing In..." : "Sign In"}
              </button>

              <div className="flex flex-col gap-2">
                <p className="text-[12px] text-white">
                  Forgot Password?{" "}
                  <a
                    className="font-bold cursor-pointer select-none"
                    rel="noopener noreferrer"
                    onClick={() => redirect('forgot-password')}
                  >
                    Click Here
                  </a>
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[12px] text-white">
                  Don't Have An Account?{" "}
                  <a
                    className="font-bold cursor-pointer select-none"
                    rel="noopener noreferrer"
                    onClick={navigateToSignUp}
                  >
                    Click Here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
