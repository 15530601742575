import React, { ChangeEvent, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../router/apiEndpoints";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { SignUpContext } from "../../context/SignUpContext";
interface FormState {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  general?: string;
}

const SignUp = () => {
  const [state, setState] = useState<FormState>({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const { login } = useContext(UserContext);

  const [errors, setErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { contextSignUpData } = useContext(SignUpContext);
  const navigate = useNavigate();

  const fetchCsrfToken = async () => {
    try {
      // Fetch CSRF token from the backend
      await axios.get(`${API.HOST}${API.GET_CSRF_TOKEN}`, {
        withCredentials: true, // Ensure cookies are sent
      });

      // Extract the token from the cookie
      const token = document.cookie
        .split("; ")
        .find((row) => row.startsWith("XSRF-TOKEN="))
        ?.split("=")[1];

      if (token) {
        // Set the token in Axios headers
        axios.defaults.headers.common["X-XSRF-TOKEN"] = decodeURIComponent(token);
      } else {
        console.error("CSRF token not found in cookies.");
      }
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  };

  useEffect(() => {
    // Configure axios defaults
    axios.defaults.withCredentials = true;

    // Fetch CSRF token when component mounts
    fetchCsrfToken();

    setState(prevState => ({
      ...prevState,
      ...contextSignUpData
    }));
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
    // Clear error when user starts typing
    if (errors[e.target.name as keyof typeof errors]) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    setSuccessMessage("");

    try {
      // Fetch CSRF token before making the request
      await fetchCsrfToken();

      // Debugging: Check the CSRF token in Axios headers
      console.log("CSRF Token:", axios.defaults.headers.common["X-XSRF-TOKEN"]);

      const response = await axios.post(
        `${API.HOST}${API.AUTH.REGISTER}`,
        state,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true, // Ensure cookies are sent
        }
      );

      if (response.data) {
        setSuccessMessage("Registration successful! Redirecting...");

        // Optionally log the response
        console.log("Registration Response:", response.data);

        // Perform login and navigate
        await login();
        navigate("/music-taste");
      }
    } catch (error: any) {
      // Debugging: Log the error for detailed inspection
      console.error("Registration Error:", error);

      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setErrors({
          general: error.response.data.message,
        });
      } else {
        setErrors({
          general: "An error occurred during registration. Please try again.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-[#302D2A]">
      <div className="w-screen h-screen bg-cover bg-center fixed top-0 left-0">
        <img
          src="/img/shapes-original.png"
          alt="BG"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <main className="flex-grow relative z-[1] flex justify-center items-center">
        <div className="flex justify-center items-center w-[90%] sm:w-full h-full">
          <div className="w-full sm:w-[410px] bg-black p-12 min-[450px]:px-20 min-[450px]:py-14 flex flex-col rounded-[28px]">
            <img
              src="/img/wizmeek_logo.png"
              alt="wizmeek-logo"
              className="w-[240px] sm:w-[250px] h-auto cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-[24px] text-white font-bold mt-6 sm:mt-8">
              Sign Up
            </p>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-2 sm:gap-4 mt-4 w-full"
            >
              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Full Name</p>
                <input
                  type="text"
                  value={state.name}
                  onChange={handleChange}
                  name="name"
                  className={`rounded-[7px] text-base text-black p-2 ${
                    errors.name ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="John/Jane Doe"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Email</p>
                <input
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                  name="email"
                  className={`rounded-[7px] text-black text-base p-2 ${
                    errors.email ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="username@gmail.com"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Password</p>
                <input
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                  name="password"
                  className={`rounded-[7px] text-black dark:text-black text-base p-2 ${
                    errors.password ? "border-2 border-red-500" : ""
                  }`}
                  placeholder="Password"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-base text-white">Confirm Password</p>
                <input
                  type="password"
                  value={state.password_confirmation}
                  onChange={handleChange}
                  name="password_confirmation"
                  className={`rounded-[7px] text-black dark:text-black text-base p-2 ${
                    errors.password_confirmation
                      ? "border-2 border-red-500"
                      : ""
                  }`}
                  placeholder="Confirm Password"
                />
                {errors.password_confirmation && (
                  <p className="text-red-500 text-sm">
                    {errors.password_confirmation}
                  </p>
                )}
              </div>

              {errors.general && (
                <p className="text-red-500 text-sm text-center">
                  {errors.general}
                </p>
              )}

              {successMessage && (
                <p className="text-green-500 text-sm text-center">
                  {successMessage}
                </p>
              )}

              <p className="text-[12px] text-white text-center">
                By signing up you agree to our
                <br />
                <a className="cursor-pointer text-[#0029FE]">
                  Terms of Service{" "}
                </a>
                and{" "}
                <a className="cursor-pointer text-[#0029FE]">Privacy Policy</a>
              </p>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-[#003465] rounded-[7px] p-2 text-white text-[20px] font-bold active:scale-[0.97] disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {isLoading ? "Signing Up..." : "Sign Up"}
              </button>

              <p className="text-[12px] text-white">
                Already have an account?{" "}
                <a
                  className="font-bold cursor-pointer select-none"
                  rel="noopener noreferrer"
                  onClick={() => navigate("/login")}
                >
                  Login
                </a>
              </p>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignUp;
