"use client";
import React, { createContext, ReactNode, useEffect, useState } from "react";

// Define the interface for the context
interface SignUpContextType {
    contextSignUpData: { name: string; email: string };
    setContextSignupData: (data: { key: string, value: string }) => void;
}

// Provide default values for the context
export const SignUpContext = createContext<SignUpContextType>({
    contextSignUpData: {
        name: "",
        email: "",
    },
    setContextSignupData: (data: { key: string, value: string }) => { }, // Default dummy function for setFormData
});

// This will hold the SignUpContext data globally
export const SignUpProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [contextSignUpData, setFormData] = useState<{ name: string; email: string }>({
        name: "",
        email: "",
    });

    const getContextSignupData = () => {
        return contextSignUpData;
    }

    const setContextSignupData = (data: { key: string, value: string }) => {
        console.log(data);
        setFormData(prevState => ({
            ...prevState,
            [data.key]: data.value
        }));
    }

    useEffect(() => {
        console.log("Updated formData: ", contextSignUpData);
    }, [contextSignUpData]);

    return (
        <SignUpContext.Provider value={{ contextSignUpData, setContextSignupData }}>
            {children}
        </SignUpContext.Provider>
    );
};
