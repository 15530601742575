import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import style from './popup.module.scss';
import { SignUpContext, SignUpProvider } from "../../context/SignUpContext";
import { useNavigate } from "react-router-dom";

const CallToSignup = forwardRef((_, ref) => {
    // States
    const [isShowing, setIsShowing] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [form, setForm] = useState({
        name: {
            value: "",
            error: ""
        },
        email: {
            value: "",
            error: ""
        }
    });
    const { setContextSignupData } = useContext(SignUpContext);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showPopUp: () => setIsShowing(true),
        setMessage: (newMessage: string) => setMessage(newMessage)
    }));

    const closePopUp = () => {
        setIsShowing(false);
        setHasError(false);
        setForm(prevState => ({
            ...prevState,
            name: {
                ...prevState.name,
                error: ""
            },
            email: {
                ...prevState.email,
                error: ""
            }
        }));
    }
    
    const setFormFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: {
                ...[name],
                value: value,
                error: ""
            }
        }));

        setContextSignupData({
            key: name,
            value: value
        })
    }

    const submitForm = () => {
        setHasError(false);
        let error = false;
        if (form.name.value === "") {
            setHasError(true);
            error = true;
            setForm(prevState => ({
                ...prevState,
                name: {
                    ...prevState.name,
                    error: "Username is required."
                }
            }));
        }
        if (form.email.value === "") {
            setHasError(true);
            error = true;
            setForm(prevState => ({
                ...prevState,
                email: {
                    ...prevState.email,
                    error: "Email is required."
                }
            }));
        }
        if (!form.email.value.includes('@') || form.email.value.includes(' ')) {
            setHasError(true);
            error = true;
            setForm(prevState => ({
                ...prevState,
                email: {
                    ...prevState.email,
                    error: "Valid email address is required."
                }
            }));
        }
        if (!error) {
            navigate("/signup");
        }
    }

    const handleLoginClick = () => {
        navigate('/login');
    }

    return (
        <>
            <div className={`
                ${style.popup_bg}
                ${isShowing ? style.active : ''}
                `}>
                <div className={style.popup_wrapper}>
                    <svg 
                        className={style.popup_closeBtn} 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 384 512"
                        onClick={ closePopUp }>
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                    <p className={style.popup_text}>Join Our Community and Be Among the First to Experience Tomorrow's Music Icons!</p>
                    <p className={style.popup_action_call}>Sign Up Today!</p>
                    {/* <p>{ message }</p> */}
                    <form className={style.popup_form}>
                        {/* Name Input */}
                        <div className={style.form_group}>
                            <div className={style.form_row}>
                                <input
                                    type="text"
                                    name="name"
                                    value={form.name.value}
                                    onChange={setFormFields}
                                    id="name-input"
                                    placeholder=""
                                    aria-labelledby="name-input-label"
                                    aria-describedby="name-input-description" />
                                <label 
                                    htmlFor="name-input"
                                    id="name-input-label"
                                    className={`${hasError && (form.name.error != "") ? style.label_warning : ""}`}>
                                    {form.name.error ? form.name.error : "Name*"}
                                </label>
                                <span 
                                    id="name-input-description"
                                    className={style.form_input_description}>
                                    Please, type your name here
                                </span>
                            </div>
                        </div>
                        {/* // Name Input */}

                        {/* Email Input */}
                        <div className={style.form_group}>
                            <div className={style.form_row}>
                                <input 
                                    type="email"
                                    name="email"
                                    value={form.email.value}
                                    onChange={setFormFields}
                                    id="email-input"
                                    placeholder=""
                                    aria-labelledby="email-input-label"
                                    aria-describedby="email-input-description" />
                                <label 
                                    htmlFor="email-input"
                                    id="email-input-label"
                                    className={`${hasError && (form.email.error != "") ? style.label_warning : ""}`}>
                                    {form.email.error ? form.email.error : "Email*"}
                                </label>
                                <span 
                                    id="email-input-description"
                                    className={style.form_input_description}>
                                    Please, enter your email here
                                </span>
                            </div>
                        </div>
                        {/* // Email Input */}

                        {/* Submit Button */}
                        <div className={style.form_group}>
                            <div className={style.form_row}>
                                <button 
                                    type="button"
                                    className={style.form_submit}
                                    aria-describedby="submit-description"
                                    onClick={submitForm}>
                                    Sign Up!
                                </button>
                                <span 
                                    className={style.form_input_description}
                                    id="submit-description"
                                    role="tooltip">
                                    Click to proceed with registration
                                </span>
                            </div>
                        </div>
                        {/* // Submit Button */}

                        <p 
                            className={style.login_string}>
                                Already have an account? <span onClick={handleLoginClick}><strong>Login</strong></span>
                        </p>
                    </form>
                    
                </div>
            </div>
        </>
    );
});

export default CallToSignup;