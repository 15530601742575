import MediaCard from "../../../components/global/mediaCard";
import { useEffect, useRef, useState } from "react";
import MediaDetail from "../../../components/global/mediaDetail";
import { fetchGenresList, fetchMediaDataByArtist } from "../../../helpers/fetcher";
import { useParams } from "react-router-dom";
import CallToSignup from "../../../components/popups/callToSignup";
import MediaPlaceHolder from "../../../components/global/mediaPlaceholder";
import style from "./../music_videos/musicVideos.module.scss";

const ArtistMedia = () => {
  const popupRef = useRef<{ showPopUp: () => void; setMessage: (msg: string) => void } | null>(null);
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([
    { id: 0, label: "All" }
  ]) ;

  useEffect(() => {
    const loadGenres = async () => {
      try {
        const data = await fetchGenresList();

        setTypes((prev) => {
          // Merge arrays and remove duplicates
          const merged = [...prev, ...data];
          const unique = Array.from(new Set(merged.map((genre) => JSON.stringify(genre))))
            .map((str) => JSON.parse(str)); // Use an arrow function to properly handle JSON.parse

          return unique;
        });
      } catch (error) {
        console.error("Error fetching genres:", error);
      }
    };

    loadGenres();
  }, []);

  const { id: artistId } = useParams();

  const [type, setType] = useState(types[0]);
  const handleType = (d: any) => {
    console.log(d);
    setType(d);
    setOpen(false);

    const fetchVideosByGenre = async () => {
      setMyData([]);
      setIsLoading(true);
      const data = await fetchMediaDataByArtist(3, Number(artistId), 1, d.label);
      console.log(data);
      setMyData(data.data);
      setIsLoading(false);
    }

    fetchVideosByGenre();
  };
  
  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  
  const [openItem, setOpenItem] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const [isLoading, setIsLoading] = useState(false); // Prevents duplicate fetches
  const [myData, setMyData] = useState<any[]>([]);

  const handleClick = (data: any) => {
    setData(data);
    setOpenItem(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };


  useEffect(() => {
      const getArtistData = async (id: number) => {
        setIsLoading(true);
        const data = await fetchMediaDataByArtist(3, id, 1);
        // console.log({ privacyData: data.data });
        setMyData(data.data);
        setIsLoading(false);
      }
      getArtistData(Number(artistId));
  }, [artistId]);

  return (
    <div className="">

      {/* Popup - Call To Signup */}
      <CallToSignup ref={popupRef} />
      {/* // Popup - Call To Signup */}

      {openItem && <MediaDetail setOpen={setOpenItem} data={data} />}
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-medium text-2xl">{ myData.length ? `${myData[0].artist}: All Media` : '' }</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className={`${style.selected_genre}`}
            onClick={() => setOpen(!open)}
          >
            <div className="">
              <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${
                open ? "rotate-180" : "rotate-0"
              }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className={`${style.genres}`}>
              {types.map((d: any, index: number) => (
                <li
                  key={index}
                  onClick={() => handleType(d)}
                >
                  {/* {console.log(d.id, type.id, d.id == type.id)} */}
                  {d.label}
                  {d.id === type.id ? (
                    <span className={`${style.selected_li_flag}`}></span>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4">
            {myData.map((d: any, index: number) => (
                <div>
                  <MediaCard 
                    {...d} 
                    idx={index} 
                    onClick={() => handleClick(d)}
                    setSignUpPopUpOpen={ () => popupRef.current?.showPopUp() }
                    setSignUpPopUpMessage={ (newMessage: string) => popupRef.current?.setMessage(newMessage) } />
                </div>
              ))}
              {isLoading && <MediaPlaceHolder placeholdersCount={3} placeholderType="media" />}
              {!hasMore && <p>No more media available.</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArtistMedia;