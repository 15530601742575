import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { YouTubeVideo } from "../../../interfaces/YouTubeVideoInterface";
import axiosInstance from "../../../helpers/axiosInstance";
import { API } from "../../../router/apiEndpoints";
import CallToSignup from "../../../components/popups/callToSignup";
import { Helmet } from "react-helmet";
import MediaCard from "../../../components/global/mediaCard";
import { UserContext } from "../../../context/UserContext";
import MediaPlaceHolder from "../../../components/global/mediaPlaceholder";
import style from './SingleVideo.module.scss';
import { fetchMediaData, fetchMediaDataByTaste } from "../../../helpers/fetcher";
import Cookies from "js-cookie";
import useListenToVideoComments from "../../../hooks/listenToNewComments";
import echo from "../../../echo";
import { YouTubeVideoComment } from "../../../interfaces/YouTubeVideoCommentInterface";
import { useTheme } from "../../../context/themeContext";

interface CommentPostedEvent {
    comment: YouTubeVideoComment[];
}

const SingleVideo = () => {
    const popupRef = useRef<{ showPopUp: () => void; setMessage: (msg: string) => void } | null>(null);
    const { id: youtubeId } = useParams();
    const [mainVideo, setMainVideo] = useState<YouTubeVideo | null>(null);
    const [videosList, setVideosList] = useState<YouTubeVideo[] | []>([]);
    const [isMainVideoLoading, setIsMainVideoLoading] = useState<boolean>(false);
    const [areMainVideoCommentsLoading, setAreMainVideoCommentsLoading] = useState<boolean>(true);
    const [isVideoListLoading, setIsVideoListLoading] = useState<boolean>(false);
    const [hasMoreVideos, setHasMoreVideos] = useState<boolean>(true);
    const { isLoggedIn } = useContext(UserContext);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isRelatedTabSelected, setIsRelatedTabSelected] = useState<boolean>(true);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const {darkMode} = useTheme();

    useEffect(() => {
        setCurrentPage(1);
    }, [youtubeId, isRelatedTabSelected])

    useEffect(() => {
        const handleInitialLoad = async () => {
            if (isRelatedTabSelected) {
                setIsMainVideoLoading(true);
                setIsVideoListLoading(true);
                setAreMainVideoCommentsLoading(true);
                axiosInstance.get(`${API.HOST}${API.VIDEOS.GET_SINGLE}${youtubeId}`).then((response) => {
                    setMainVideo(response.data.data.video);
                    setVideosList(response.data.data.related_videos.data);
                    if (response.data.data.related_videos.pagination.next_page_url != null) {
                        setHasMoreVideos(true);
                    } else {
                        setHasMoreVideos(false);
                    }
                    axiosInstance.get(`${API.HOST}${API.COMMENTS.GET_FOR_SINGLE_VIDEO}${response.data.data.video.id}`).then((response) => {
                        console.log(response);
                        setMainVideo((prevState: any) => ({
                            ...prevState,
                            comments: response.data
                        }));
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        setAreMainVideoCommentsLoading(false);
                    });
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setIsMainVideoLoading(false);
                    setIsVideoListLoading(false);
                });
            } else {
                setVideosList([]);
                setIsVideoListLoading(true);
                axiosInstance.get(`${API.HOST}${API.VIDEOS.GET_SINGLE}${youtubeId}`).then((response) => {
                    setMainVideo(response.data.data.video);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setIsMainVideoLoading(false);
                    setIsVideoListLoading(false);
                });
                await fetchMediaDataPaginated(3, "latest", "", 1);
                setIsVideoListLoading(false);
            }
        }

        handleInitialLoad();
    }, [youtubeId]);

    useEffect(() => {
        if (!mainVideo?.id) return;

        const channelName = `video.${mainVideo.id}`;
        const channel = echo.channel(channelName);
        channel.listen(".comment.added", (data: CommentPostedEvent) => {
            console.log('Event: ', data);
            setMainVideo((prevState: any) => ({
                ...prevState,
                comments: [...prevState.comments, data.comment],
            }));
        });
    }, [mainVideo?.id]);

    useEffect(() => {
        const handleScroll = async () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100 && // Near bottom
                hasMoreVideos &&
                !isVideoListLoading
            ) {
                if (isRelatedTabSelected) {
                    setIsMainVideoLoading(true);
                    setIsVideoListLoading(true);
                    axiosInstance.get(`${API.HOST}${API.VIDEOS.GET_SINGLE}${youtubeId}?page=${(currentPage + 1)}`).then((response) => {
                        setVideosList((previousState) => [
                            ...previousState,
                            ...response.data.data.related_videos.data
                        ]);
                        if (response.data.data.related_videos.pagination.next_page_url != null) {
                            setHasMoreVideos(true);
                        } else {
                            setHasMoreVideos(false);
                        }
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        setIsMainVideoLoading(false);
                        setIsVideoListLoading(false);
                        setCurrentPage(currentPage + 1);
                    });
                } else {
                    const newPage = currentPage + 1;
                    setIsVideoListLoading(true);
                    await fetchMediaDataPaginated(3, "latest", "", newPage);
                    setIsVideoListLoading(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [currentPage, hasMoreVideos, isVideoListLoading]);

    useEffect(() => {
        // console.log('Rendered page: ', currentPage);
    }, [currentPage]);

    const switchRelatedTabs = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const isRelatedClicked = e.currentTarget.dataset.related === 'true';
        const { tabType } = e.currentTarget.dataset;

        // Ensure correct transitions
        if (isRelatedTabSelected && tabType !== 'taste_based' && tabType !== 'available_based') return;
        if (!isRelatedTabSelected && tabType !== 'related') return;

        setVideosList([]);
        setIsVideoListLoading(true);
        setHasMoreVideos(true);
        setCurrentPage(1);

        if (isRelatedClicked) {
            console.log('Related Clicked');
            setIsRelatedTabSelected(true);


            try {
                const response = await axiosInstance.get(`${API.HOST}${API.VIDEOS.GET_SINGLE}${youtubeId}`);
                console.log(`Response:`, response);

                setVideosList(response.data.data.related_videos.data);
                setHasMoreVideos(response.data.data.related_videos.pagination.next_page_url !== null);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log('Not related Clicked');
            setIsRelatedTabSelected(false);

            try {
                await fetchMediaDataPaginated(3, 'latest', "", 1);
            } catch (error) {
                console.error(error);
            }
        }

        setIsVideoListLoading(false);
        setIsInitialLoad(false);
    };


    useEffect(() => {
        console.log(videosList);
    }, [videosList]);

    const fetchMediaDataPaginated = async (
        limit: number,
        mode: string,
        genre: string,
        page: number
    ) => {
        if (isVideoListLoading || (!isInitialLoad && !hasMoreVideos)) return;

        try {
            const response =
                isLoggedIn || Cookies.get("isLoggedIn")
                    ? await fetchMediaDataByTaste(limit, mode, genre, page)
                    : await fetchMediaData(limit, mode, genre, page);

            // console.log(response);
            if (response?.data?.length > 0) {
                // For initial load, replace media data instead of appending
                if (isInitialLoad) {
                    setVideosList(response.data);
                } else {
                    setVideosList((prev) => [...prev, ...response.data]);
                }

                setHasMoreVideos(response.pagination.next_page_url !== null); // Update hasMore based on the API response
            } else {
                setHasMoreVideos(false); // No more data
            }

            // Update currentPage only if it's not the initial load
            if (!isInitialLoad) setCurrentPage(page);
        } catch (error) {
            console.error("Error fetching media data:", error);
        } finally {
        }
    };



    return (
        <>
            {/* Popup - Call To Signup */}
            <CallToSignup ref={popupRef} />
            {/* // Popup - Call To Signup */}

            <Helmet>
                <title>{`${mainVideo?.artist} - ${mainVideo?.title} watch on Wizmeek`}</title>
                <meta
                    name="description"
                    content="Explore the world of indie music and discover talented artists who deserve more recognition. Find new music gems and support emerging artists."
                />
            </Helmet>

            <div className="space-y-4">
                <div className="flex max-xl:flex-col justify-between max-lg:items-center gap-8">
                    <iframe
                        className="flex-grow flex-shrink-0 max-lg:w-full !aspect-video"
                        src={`https://www.youtube.com/embed/${mainVideo?.youtube_id}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen={true}
                        data-vidid=""
                    ></iframe>
                    <MediaCard
                        cardType="detail"
                        className="xl:w-[31.5%] w-full"
                        {...mainVideo}
                        setSignUpPopUpOpen={() => popupRef.current?.showPopUp()}
                        setSignUpPopUpMessage={(newMessage: string) => popupRef.current?.setMessage(newMessage)}
                        areMainVideoCommentsLoading={areMainVideoCommentsLoading}
                    />
                </div>
            </div>


            <div className="mt-6">
                <div className={style.realtedTabs}>
                    <button
                        data-related='true'
                        data-tab-type='related'
                        onClick={(e) => switchRelatedTabs(e)}
                        className={`
                            font-medium text-2xl
                            ${isRelatedTabSelected && style.selected}
                            ${!darkMode && style.light_mode}`}>
                        Related Media
                    </button>
                    {isLoggedIn ? (
                        <button
                            data-related='false'
                            data-tab-type='taste_based'
                            onClick={(e) => switchRelatedTabs(e)}
                            className={`
                                font-medium text-2xl
                                ${!isRelatedTabSelected && style.selected}
                                ${!darkMode && style.light_mode}`}>
                            Based on Your Taste
                        </button>
                    ) : (
                        <button
                            data-related='false'
                            data-tab-type='available_based'
                            onClick={(e) => switchRelatedTabs(e)}
                            className={`
                                font-medium text-2xl
                                ${!isRelatedTabSelected && style.selected}
                                ${!darkMode && style.light_mode}`}>
                            Available for You
                        </button>
                    )}
                </div>
                <div className="mt-8">
                    <div className="grid gird-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                        {videosList.map((d: YouTubeVideo) => (
                            <div key={d.id}>
                                <MediaCard
                                    {...d}
                                    idx={d.id}
                                    setSignUpPopUpOpen={() => popupRef.current?.showPopUp()}
                                    setSignUpPopUpMessage={(newMessage: string) => popupRef.current?.setMessage(newMessage)} />
                            </div>
                        ))}
                        {isVideoListLoading && <MediaPlaceHolder placeholdersCount={3} placeholderType="media" />}
                        {!hasMoreVideos && <p>No more media available.</p>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleVideo;