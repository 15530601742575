import { useContext, useEffect, useRef, useState } from "react";
import ArtistCard from "./artistCard";
import { fetchArtistsList, fetchGenresList, fetchMediaDataByArtist } from "../../../helpers/fetcher";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import CallToSignup from "../../../components/popups/callToSignup";
import style from "./../music_videos/musicVideos.module.scss";

interface ArtistInterface {
  _id: number
  apple_music_link: string,
  bio: string,
  cover: string,
  instagram_link: string,
  nFan: number,
  name: string,
  shareLink: string,
  spotify_link: string,
  type: string,
}
const Artists = () => {
  const popupRef = useRef<{ showPopUp: () => void; setMessage: (msg: string) => void } | null>(null);
  const { isLoggedIn } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [artists, setArtists] = useState<ArtistInterface[] | []>([]);
  const [types, setTypes] = useState([{ id: 0, label: "All" }]);

  useEffect(() => {
    const loadGenres = async () => {
      try {
        const data = await fetchGenresList();
        // console.log(data);
        setTypes((prev) => {
          // Merge arrays and remove duplicates
          const merged = [...prev, ...data];
          const unique = Array.from(new Set(merged.map((genre) => JSON.stringify(genre))))
            .map((str) => JSON.parse(str)); // Use an arrow function to properly handle JSON.parse

          return unique;
        });
      } catch (error) {
        console.error("Error fetching genres:", error);
      }
    };

    loadGenres();
  }, []);


  const [type, setType] = useState(types[0]);
  const handleType = (d: any) => {
    // console.log(d);
    setType(d);
    setOpen(false);

    const fetchByGenre = async () => {
      const data = await fetchArtistsList(`${d.id}`);
      console.log(data);
      setArtists([...data]);
    }

    fetchByGenre();
  };

  useEffect(() => {
    console.log('artists updated: ', artists);
  }, [artists]);

  useEffect(() => {
    const getArtists = async () => {
      const data = await fetchArtistsList();
      // console.log({ artistData: data })
      setArtists(data);
    }

    getArtists();
  }, []);

  // Effect to handle clicks outside the searchRef
  const typeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (typeRef.current && !typeRef.current.contains(event.target as Node)) {
        setOpen(false); // Close search results if clicked outside
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef]);
  return (
    <div className="mt-4">
      {/* Popup - Call To Signup */}
      <CallToSignup ref={popupRef} />
      {/* // Popup - Call To Signup */}

      <div className="flex justify-between items-center">
        <h1 className="font-medium text-2xl">Artists</h1>
        <div className="relative text-white" ref={typeRef}>
          <button
            className={`${style.selected_genre}`}
            onClick={() => setOpen(!open)}
          >
            <div className="">
              <span>{type.label}</span>
            </div>
            <span
              className={`absolute top-1/2 right-2 -translate-y-1/2 transition-all ${open ? "rotate-180" : "rotate-0"
                }`}
            >
              <svg
                width="23"
                height="11"
                viewBox="0 0 23 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 11L23 2.0963L20.2963 0L11.5 6.81482L2.70566 0L0 2.09482L11.5 11Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className={`${style.genres}`}>
              {types.map((d: any, index: number) => (
                <li
                  key={index}
                  onClick={() => handleType(d)}
                >
                  {/* {console.log(d.id, type.id, d.id == type.id)} */}
                  {d.label}
                  {d.id === type.id ? (
                    <span className={`${style.selected_li_flag}`}></span>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-8">
          <div className="space-y-6">
            {artists.map((artist: ArtistInterface) => (
              <ArtistCard
                {...artist}
                key={artist._id}
                setSignUpPopUpOpen={() => popupRef.current?.showPopUp()}
                setSignUpPopUpMessage={(newMessage: string) => popupRef.current?.setMessage(newMessage)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Artists;
